export const getEnumList = enumItem => {
    let enumArr = []
    for (let item in enumItem) {
        enumArr.push({
            id: enumItem[item],
            name: item
        })
    }
    return enumArr
}
export const getEnumText = (id, enums) => {
    for (let item in enums) {
        if (enums[item] == id) return item
    }
    return ''
}
// 获取多个枚举字符串
export const getEnumTexts = (str, enums) => {
    if (!str) return ''
    let arr = str.split(',').filter(x => !!x).map(x => parseInt(x))
    let resArr = []
    for (let item in enums) {
        if (arr.includes(enums[item])) resArr.push(item)
    }
    return resArr.length ? resArr.reduce((a, b) => a + ',' + b) : ''
}
// 项目状态
export const adminProjectStateEnum = {
    已创建: 1,
    进行中: 2,
    暂停中: 3,
    测试中: 4,
    已结束: 5
}
// 任务状态
export const adminTaskStateEnum = {
    已创建: 1,
    进行中: 2,
    暂停中: 3,
    测试中: 4,
    已结束: 5
}
// 模块类型
export const moduleTypeEnum = {
    模块: 0,
    页面: 1,
    功能: 2,
}
// 功能版本
export const systemProductEnum = {
    基础版: 1,
    专业版: 2,
    仓库版: 3,
    // 企业版: 4,
}
// 文章类型
export const articleTypeEnum = {
    医疗政策: 1,
    求职技巧: 2,
    义齿加工: 3,
    会议展会: 4,
    媒体曝光: 5,
    问答: 6,
    口腔知识: 7,
    义齿材料: 8,
    黑名单: 9
}
export const fileUseEnum = {
    用户头像: 1,
    用户证书: 2,
    单位Logo: 3,
    单位照片: 4,
    营业执照: 5,
    文章封面: 6,
    文章图片: 7,
    帮助: 8,
    广告: 9,
    文章附件: 10,
    聊天文件: 11,
    举报文件: 12,
    发票: 13
}
// 文件夹前缀
export const folderPrefix = {
    用户头像: 'userAvatar',
    用户证书: 'userLicense',
    单位Logo: 'companyLogo',
    单位照片: 'companyInfo',
    营业执照: 'businessLicense',
    文章封面: 'articleCover',
    文章图片: 'articlePic',
    帮助: 'help',
    广告: 'advertise',
    文章附件: 'articleFile',
    聊天文件: 'chatFile',
    举报文件: 'reportFile',
    发票: 'invoice',
}
// 文章标签
export const articleTagEnum = [
    '固定', '活动', '种植', '正畸', '全口', '基台', 'CAD/CAM', '模型', '蜡型', '铸造', '车金', '上瓷', 'OP', '铸瓷', '车瓷',
    '排牙', '胶托', '口扫', '数字化', '消毒', '排版', '切削', '打磨', '马龙桥', '外返', '内返', '过程质检', '出厂质检', '口腔展会',
    '临床', '销售', '医疗器械', '行业趋势', '医技沟通', '政策法规', '黑名单', '口腔知识', '材料设备'
]
// 企业类型
export const companyTypeEnum = {
    义齿工厂: 1,
    医院门诊: 2,
    医疗器械公司: 3,
    软件公司: 4,
    医药药店: 5,
    办证公司: 6,
}
// 休息类型
export const restTypeEnum = {
    周休: 1,
    月休: 2
}

// 加班情况
export const overtimeEnum = {
    不加班: 1,
    偶尔加班: 2,
    弹性工作: 3
}
// 审核状态
export const auditStateEnum = {
    不通过: -1,
    待审核: 0,
    通过: 1,
}

// 单位性质
export const companyNatureEnum = {
    民营: 1,
    中外合资: 2,
    外商独资: 3,
    国企: 4,
    上市公司: 5,
}

// 广告类型
export const adTypeEnum = {
    论坛左侧A区: 1,
    // 论坛右侧B区: 2,
    论坛右下C区: 3,
    // 求职左侧A区: 4,
    // 求职右侧B区: 5,
    // 求职右侧C区: 6,
    首页Banner广告: 7,
}
// 广告大小
export const adSizeEnum = {
    论坛左侧A区: '140*280',
    论坛右侧B区: '140*280',
    论坛右下C区: '220*90',
    首页Banner广告: '1260*320'
}
// 搜索薪资范围
export const searchSalaryList = [
    {
        id: 1,
        name: '1~3K'
    },
    {
        id: 2,
        name: '3~5K'
    },
    {
        id: 3,
        name: '5~10K'
    },
    {
        id: 4,
        name: '10~15K'
    },
    {
        id: 5,
        name: '15~20K'
    },
    {
        id: 6,
        name: '20~30K'
    },
    {
        id: 7,
        name: '30~50K'
    },
    {
        id: 8,
        name: '50K以上'
    },
]

// 目前状态
export const jobStateEnum = {
    '离职-随时到岗': 1,
    '在职-暂不考虑': 2,
    '在职-考虑机会': 3,
    '在职-月内到岗': 4,
    '其他/学生': 5
}
// 工作性质
export const jobNatureEnum = {
    全职: 1,
    实习: 2,
    在家兼职: 3,
    坐班兼职: 4,
    需要规培: 5,
    需要进修: 6,
    合伙: 7,
    多点执业: 8,
    飞刀: 9
}
// 性别
export const genderEnum = {
    男: 1,
    女: 2
}
// 用户投诉
export const reportStateEnum = {
    已创建: 1,
    已处理: 2,
    已忽略: 3,
}
// 软件类型
export const softwareTypeEnum = {
    义齿管理软件: 1,
    口腔软件: 2,
    门诊医院: 3,
    医药药店: 4,
    医疗器械经营: 5,
}
// 用户类型
export const cunzhiUserTypeEnum = {
    个人: 1,
    企业单位: 2,
}
// 平台
export const regSourceEnum = {
    存知OA: 1,
    齿联招聘网站: 2,
    齿联招聘小程序: 3,
    技工助手: 4,
    存知工厂平台: 5,
}
// 转让合作类型
export const transferTypeEnum = {
    口腔门诊转让: 1,
    义齿工厂转让: 2,
    口腔门诊合作: 3,
    义齿工厂合作: 4,
    牙科承包: 5
}

// 办证类型
export const factoryCertTypeEnum = {
    营业执照: 101,
    医疗器械生产许可证: 102,
    医疗器械注册证: 103,
    二类医疗器械经营备案凭证: 104,
    三类医疗器械经营许可证: 105,
    一类医疗器械备案凭证: 106,
    一类医疗器械生产备案凭证: 107,
    产品检测: 108,
    研究验证: 109,
    辐射安全许可证: 110,
    医疗器械生产质量管理体系认证: 111,
    'CE、FDA认证': 112,
    'ISO13485、9001认证': 113,
    医疗器械网络销售备案凭证: 114,
    互联网药品信息服务许可证书: 115,
    医疗器械广告审查表: 116,
    进出口贸易备案: 117,
    质量负责人资质: 118,
    '电磁兼容(EMC)预检': 119,
}
export const hospitalCertTypeEnum = {
    营业执照: 201,
    医疗机构执业许可证: 202,
    诊所备案凭证: 203,
    医保定点单位: 204,
    医师执业范围变更: 205,
    放射诊疗许可证: 206,
    辐射安全许可证: 207,
    母婴保健技术服务执业许可证: 208,
    医疗器械经营许可证: 209,
    药品经营许可证: 210,
    互联网药品信息服务许可证书: 211,
    GSP认证: 212,
    食品经营许可证: 213,
}
// 获取办证类型
export const getCertTypeArr = str => {
    if (!str) return []
    let arr = str.split(',').filter(x => !!x).map(x => parseInt(x))
    let resArr = []
    for (let item in factoryCertTypeEnum) {
        if (arr.includes(factoryCertTypeEnum[item])) resArr.push(item)
    }
    for (let item in hospitalCertTypeEnum) {
        if (arr.includes(hospitalCertTypeEnum[item])) resArr.push(item)
    }
    return resArr
}
export const getCertTypes = str => {
    let resArr = getCertTypeArr(str)
    return resArr.length ? resArr.reduce((a, b) => a + ',' + b) : ''
}

export const getCertTypeShorts = str => {
    let resStr = getCertTypes(str)
    if (!resStr) return ''
    let resArr = resStr.split(',')
    if (resArr.length > 2) {
        return resArr.filter((x, index) => index < 2).join(',') + '等'
    } else {
        return resStr
    }
}
// 设备类型
export const machineTypeList = [
    {
        id: 1,
        name: '义齿公司',
        children: [
            {id: 1, name: '咬合牙架'},
            {id: 2, name: '灭菌器'},
            {id: 3, name: '影像仪'},
            {id: 4, name: '数显式推拉力计'},
            {id: 5, name: '质保卡打印机'},
            {id: 6, name: '干磨机'},
            {id: 7, name: '水磨机'},
            {id: 8, name: '真空搅拌机'},
            {id: 9, name: '烤蜡炉'},
            {id: 10, name: '铸造机'},
            {id: 11, name: '镀金机'},
            {id: 12, name: '电解机'},
            {id: 13, name: 'X光透视仪'},
            {id: 14, name: '粉液配比机'},
            {id: 15, name: '压胶机'},
            {id: 16, name: '烤瓷炉'},
            {id: 17, name: '铸瓷炉'},
            {id: 18, name: '预热炉'},
            {id: 19, name: '上釉炉'},
            {id: 20, name: '固化机'},
            {id: 21, name: '电阻烤炉'},
            {id: 22, name: '烤箱'},
            {id: 23, name: '聚合炉'},
            {id: 24, name: '结晶炉'},
            {id: 25, name: '退火炉'},
            {id: 26, name: '切割机'},
            {id: 27, name: '筛粉机'},
            {id: 28, name: '车床'},
            {id: 29, name: '口扫仪'},
            {id: 30, name: '扫描仪'},
            {id: 31, name: '雕铣机'},
            {id: 32, name: '加热炉'},
            {id: 33, name: '义齿加工机'},
            {id: 34, name: '切削机'},
            {id: 35, name: '铲件机'},
            {id: 36, name: '烘干机结晶炉'},
            {id: 37, name: '打印机'},
            {id: 38, name: 'UPS断电保护'},
            {id: 39, name: '牙模处理器'},
            {id: 40, name: '打磨手机'},
            {id: 41, name: '喷砂机'},
            {id: 42, name: '种钉机'},
            {id: 43, name: '熔蜡器'},
            {id: 44, name: '抛光机'},
            {id: 45, name: '研磨仪'},
            {id: 46, name: '电解机'},
            {id: 47, name: '蒸汽机'},
            {id: 48, name: '聚合炉'},
            {id: 49, name: '清洗机'},
            {id: 50, name: '震荡器'},
        ]
    },
    {
        id: 2,
        name: '口腔',
        children: [
            {id: 1, name: '牙椅'},
            {id: 2, name: '手机'},
            {id: 3, name: '空压机'},
            {id: 4, name: '内窥镜'},
            {id: 5, name: '牙髓测量仪'},
            {id: 6, name: '洁牙机'},
            {id: 8, name: '根管治疗仪'},
            {id: 8, name: '美白仪'},
            {id: 9, name: 'X光机'},
            {id: 10, name: '全景机'},
            {id: 11, name: 'CBCT'},
            {id: 12, name: '口内扫描仪'},
            {id: 13, name: '牙髓测量仪'},
            {id: 14, name: '根管测量仪'},
            {id: 15, name: '光敏固化仪'},
            {id: 16, name: '美白仪'},
            {id: 17, name: '传感器'},
            {id: 18, name: '打磨机'},
            {id: 19, name: '种植机'},
            {id: 20, name: '根管治疗仪'},
            {id: 21, name: '蒸馏水机'},
            {id: 22, name: '超声骨刀'},
            {id: 23, name: '注油机'},
            {id: 24, name: '牙周治疗仪'},
            {id: 25, name: '污水处理器'},
        ]
    },
    {
        id: 3,
        name: '药店',
        children: [
            {id: 1, name: '药品柜/架'},
            {id: 2, name: '调剂台/柜'},
            {id: 3, name: '冷藏柜'},
            {id: 4, name: '收银系统'},
            {id: 5, name: '管理系统'},
        ]
    },
    {
        id: 4,
        name: '行政后勤',
        children: [
            {id: 1, name: '办公桌/椅/柜'},
            {id: 2, name: '电脑'},
            {id: 3, name: '电视'},
            {id: 4, name: '打印机'},
            {id: 5, name: '发电机'},
            {id: 6, name: '输液椅'},
            {id: 7, name: '污水处理器'},
        ]
    },
    {
        id: 5,
        name: '通用设备',
        children: []
    },
]
// 获取设备类型名称
export const getMachineTypeStr = (firstType, secondType) => {
    if (firstType) {
        let item = machineTypeList.find(x => x.id === firstType)
        if (!item) return ''
        if (secondType) {
            let second = item.children.find(x => x.id === secondType)
            if (second) {
                return item.name + ' - ' + second.name
            } else {
                return item.name
            }
        } else {
            return item.name
        }
    } else {
        return ''
    }
}