// 文件管理
import {reactive, ref, computed} from 'vue'
import {ossTokenGet, fileAdd} from "@/api/system";
import {useStore} from 'vuex'
import moment from 'moment'
import OSS from 'ali-oss'
import SparkMD5 from 'spark-md5'
import {getRandomString} from "@/util/common";
import {ElMessage} from "element-plus";
import Compressor from 'compressorjs';

// 图片上传前压缩
export const beforePicUpload = async file => {
    console.log('before=' + file.size)
    // 文件类型限制为jpg/png/jpeg
    const fileTypeCheck = file.type.indexOf('jpg') >= 0 || file.type.indexOf('jpeg') >= 0 || file.type.indexOf('png') >= 0
    if (!fileTypeCheck) {
        ElMessage.error("上传文件只能是jpg、jpeg、png格式");
    }
    // 文件大小限制为4M
    const fileLimit = file.size / 1024 / 1024 < 4;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过4M！");
    }
    if (!(fileLimit && fileTypeCheck)) {
        return Promise.reject()
    }
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            success: result => {
                console.log(result.size)
                resolve(result)
            },
            error(err) {
                console.log(err.message);
                reject(false)
            },
        })
    })
}
// 头像 上传前压缩 宽高不超过300
export const beforeAvatarUpload = file => {
    console.log('before=' + file.size)
    // 文件类型限制为jpg/png/jpeg
    const fileTypeCheck = file.type.indexOf('jpg') >= 0 || file.type.indexOf('jpeg') >= 0 || file.type.indexOf('png') >= 0
    if (!fileTypeCheck) {
        ElMessage.error("上传文件只能是jpg、jpeg、png格式");
    }
    // 文件大小限制为10M
    const fileLimit = file.size / 1024 / 1024 < 10;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过10M！");
    }
    if (!(fileLimit && fileTypeCheck)) {
        return Promise.reject()
    }
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            maxWidth: 300,
            maxHeight: 300,
            success: result => {
                console.log(result.size)
                resolve(result)
            },
            error(err) {
                console.log(err.message);
                reject(false)
            },
        })
    })
}
// 文件大小不超过2M
export const beforeMax4MUpload = file => {
    // 文件大小限制为M
    const fileLimit = file.size / 1024 / 1024 < 2;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过2M！");
    }
    return fileLimit
}

// OSS身份
export function useGetOssToken() {
    const ossToken = reactive({
        endPoint: '',
        accessKeyId: '',
        accessKeySecret: '',
        securityToken: '',
        expiration: '',
        bucketName: '',
        region: ''
    })
    const store = useStore()
    // 检查是否有token以及token是否过期
    const checkOssToken = () => {
        let storeOssToken = store.state.ossToken
        if (storeOssToken && storeOssToken.securityToken && storeOssToken.expiration) {
            // 还有10分钟过期 需要重新过去token
            return moment(storeOssToken.expiration).isAfter(moment().add(10, 'minutes'))
        } else {
            return false
        }
    }
    const getOssTokenLoading = ref(false)
    const getOssToken = async () => {
        if (getOssTokenLoading.value) return
        getOssTokenLoading.value = true
        // 如果token没有或者快过期了 重新获取token
        if (!checkOssToken()) {
            let res = await ossTokenGet()
            if (res) {
                Object.assign(ossToken, res)
                store.commit('setOssToken', res)
            }
        } else {
            Object.assign(ossToken, store.state.ossToken)
        }
        getOssTokenLoading.value = false
    }
    const getOssClient = async () => {
        await getOssToken()
        const client = new OSS({
            region: ossToken.region,
            accessKeyId: ossToken.accessKeyId,
            accessKeySecret: ossToken.accessKeySecret,
            stsToken: ossToken.securityToken,
            refreshSTSToken: async () => {
                // 向您搭建的STS服务获取临时访问凭证。
                await getOssToken()
                return {
                    accessKeyId: ossToken.accessKeyId,
                    accessKeySecret: ossToken.accessKeySecret,
                    stsToken: ossToken.stsToken
                }
            },
            // 刷新临时访问凭证的时间间隔，单位为毫秒。
            refreshSTSTokenInterval: 300 * 1000,
            // 填写Bucket名称。
            bucket: ossToken.bucketName,
        })
        return client
    }
    return {
        ossToken,
        getOssTokenLoading,
        getOssToken,
        getOssClient,
    }
}

// 生成随机文件名称
function generateFileName() {
    return moment().format('yyyyMMDDHHmmss') + getRandomString(6)
}

// 获取图片URL
export function useGetFileUrl() {
    const {ossToken, getOssToken} = useGetOssToken()
    const getFileUrl = async fileToken => {
        if (!fileToken) return
        if (fileToken.includes('http:') || fileToken.includes('https:')) {
            return fileToken
        } else {
            // let ossClient = await getOssClient()
            // return ossClient.signatureUrl(fileToken)
            await getOssToken()
            return 'https://' + ossToken.bucketName + '.oss-cn-shenzhen.aliyuncs.com/' + fileToken
        }
    }
    return {
        getFileUrl,
    }
}

export function useUploadFile() {
    // 新增数据库文件记录
    const addFileParam = reactive({
        fileName: '',
        orgFileName: '',
        extension: '',
        path: '',
        mimeType: '',
        fileSize: '',
        fileUse: '',
        hashStr: '',
        orgMimeType: '',
        bucket: '',
        etag: '',
    })
    const addFile = async () => {
        let result = await fileAdd(addFileParam)
        return result
    }
    // 上传必须设置
    const uploadParam = reactive({
        folderPrefix: '',
    })
    const uploadFileResult = reactive({
        isSame: 0,
        result: false,
        picId: 0,
        picToken: '',
        orgFileName: '',
    })
    const uploadFileList = ref([])
    const {getOssClient} = useGetOssToken()
    const store = useStore()
    const userId = computed(() => store.state.userId)
    const token = computed(() => store.state.token)
    const uploadFile = async param => {
        if (!token.value || !userId.value) {
            ElMessage.error('用户未登录')
            return
        }
        if (!param.file) {
            ElMessage.error('未选择文件')
            return
        }
        if (uploadFileList.value.some(x => x.orgFileName === param.file.name)) {
            ElMessage.error('已上传同名同名文件，请检查！')
            return
        }
        await uploadOssFile(param)
    }

    const uploadOssFile = async param => {
        uploadFileResult.result = false
        let file = param.file
        let hashStr = SparkMD5.ArrayBuffer.hash(file)
        addFileParam.fileSize = file.size
        addFileParam.fileUse = uploadParam.fileUse
        addFileParam.hashStr = hashStr
        addFileParam.orgFileName = file.name
        let fileNameArr = file.name.split('.')
        addFileParam.extension = fileNameArr[fileNameArr.length - 1]
        addFileParam.fileName = generateFileName() + '.' + addFileParam.extension
        addFileParam.mimeType = file.type
        addFileParam.orgMimeType = file.type
        let objectName = 'job/' + uploadParam.folderPrefix + '/' + addFileParam.fileName
        addFileParam.path = objectName
        // 先添加1条数据库记录
        let addResult = await addFile()
        if (addResult) {
            Object.assign(uploadFileResult, addResult)
            if (addResult.isSame) {
                // 相同文件以前上传过
                uploadFileList.value.push(uploadFileResult)
                return
            }
        } else {
            ElMessage.error('数据库添加文件记录失败！')
            return
        }
        // 在上传OSS
        let ossClient = await getOssClient()
        let options = {
            callback: {
                url: 'https://factory.cunzhi.net/api/factory/cunzhiFile/oss_file_callback',
                body: "bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}&fileUse=${x:fileUse}&hashStr=${x:hashStr}&orgFileName=${x:orgFileName}&newFileName=${x:newFileName}&fileExtension=${x:fileExtension}&userId=${x:userId}",
                customValue: {
                    fileUse: addFileParam.fileUse + '',
                    hashStr: addFileParam.hashStr,
                    orgFileName: addFileParam.orgFileName,
                    newFileName: addFileParam.fileName,
                    fileExtension: addFileParam.extension,
                    userId: userId.value + '',
                    size: addFileParam.fileSize + ''
                }
            }
        }
        ossClient.put(objectName, param.file, options)
        uploadFileList.value.push(uploadFileResult)
    }
    return {
        uploadParam,
        uploadFileResult,
        uploadFileList,
        uploadFile,
    }
}

export function useDownload() {
    const downFileLoading = ref(false)

    // 下载文件，自定义文件名称
    function downFile(url, fileName, token = '') {
        ElMessage.success('开始下载')
        downFileLoading.value = true
        const x = new XMLHttpRequest()
        x.open('GET', url, true)
        if (token) x.setRequestHeader("X-Auth-Token", token);
        let mainFactoryId = localStorage.getItem("mainFactoryId")
        x.setRequestHeader("mainFactoryId", mainFactoryId)
        x.responseType = 'blob'
        x.onload = function () {
            const url = window.URL.createObjectURL(x.response)
            const a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
        }
        x.send()
        x.onreadystatechange = function () {
            if (x.readyState === 4) {
                downFileLoading.value = false
            }
        }
    }

    return {
        downFile,
        downFileLoading
    }
}